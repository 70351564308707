import { inject } from '@angular/core';
import { type CanActivateFn, Router } from '@angular/router';
import { type UserConfigType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { UserContextService } from '../../shared/services/user-context/user-context.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { map, catchError, of, filter } from 'rxjs';

export const requirePermissions: (...permissions: UserConfigType['permissions']) => CanActivateFn = (
  ...permissions
) => {
  return () => {
    const router = inject(Router);
    const userContext = inject(UserContextService);

    const noPermissionsRoute = router.createUrlTree(['/error/403']);

    return toObservable(userContext.userPermissions).pipe(
      filter((userPermissions) => userPermissions !== undefined),
      map((userPermissions) => {
        if (userPermissions.length === 0 && permissions.length === 0) {
          return noPermissionsRoute;
        }

        const hasPermission = permissions.every((permission) => userPermissions!.includes(permission));

        if (userPermissions.length === 0 || !hasPermission) {
          return noPermissionsRoute;
        }

        return true;
      }),
      catchError(() => {
        return of(noPermissionsRoute);
      }),
    );
  };
};

import { type ErrorHandler, inject } from '@angular/core';
import { RUMService } from '../services/rum/rum.service';

export class RumErrorHandler implements ErrorHandler {
  private rumService = inject(RUMService);

  handleError(error: any): void {
    const awsRum = this.rumService.awsRum();

    if (awsRum) {
      awsRum.recordEvent('dpqa-error', error);
    } else {
      console.error('Error occurred:', error);
    }
  }
}

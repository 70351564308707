import { computed, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { FilterInfoGetResponseType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { type SFPType, serialize } from '@tig-dpqa-cloud/util-sfp';
import { endOfDay, endOfToday, formatISO, startOfDay, startOfToday, parseISO } from 'date-fns';
import { createInjectable } from 'ngxtension/create-injectable';
import { signalSlice } from 'ngxtension/signal-slice';
import { EMPTY, type Observable, catchError, map, shareReplay, tap } from 'rxjs';
import { HttpCommunicationService } from '../../../core/services/http-communication.service';
import { type SlideScanStatus } from '../../types/slide-scan-status';
import { RUMService } from '../rum/rum.service';

type InitialState = {
  selectedDateRange: [Date, Date];
  status: SlideScanStatus;
  staining: string[] | undefined;
  selectedFullScannerType: string | undefined;
  selectedScannerType: string | undefined;
  selectedScannerSerial: string | undefined;
  selectedRack: (number | 'unknown')[] | undefined;
  customField1: string[] | undefined;
  searchTerm: string;
  page: number;
  pageSize: number;
  selectedTag: string[] | undefined;
};

const initialState: InitialState = {
  status: 'in-queue',
  staining: undefined,
  selectedDateRange: [startOfToday(), endOfToday()],
  selectedFullScannerType: undefined,
  selectedScannerType: undefined,
  selectedScannerSerial: undefined,
  selectedRack: undefined,
  selectedTag: undefined,
  customField1: undefined,
  searchTerm: '',
  page: 1,
  pageSize: 50,
};

const getNullFilter = (arg: string[]) => {
  if (!arg || arg.length === 0) {
    return {};
  }

  const hasUnknown = arg.includes('unknown');
  if (hasUnknown && arg.length === 1) {
    return { isNull: 'true' };
  }

  return hasUnknown ? { or: { in: arg.filter((a) => a !== 'unknown'), isNull: 'true' } } : { in: arg };
};

const getGenerateFilterFuction = () => (state: InitialState) =>
  ({
    and: {
      triggeredAt: {
        and: {
          gt: startOfDay(state.selectedDateRange[0]).toISOString(),
          lt: endOfDay(state.selectedDateRange[1]).toISOString(),
        },
      },
      status: { in: [state.status] },
      staining: getNullFilter(state.staining || []),
      customField1: getNullFilter(state.customField1 || []),
      ...(state.selectedTag && { tags: { in: state.selectedTag } }),
      ...(state.selectedScannerType && { scannerType: { eq: state.selectedScannerType } }),
      ...(state.selectedScannerSerial && { scannerSerial: { like: `%${state.selectedScannerSerial}%` } }),
      ...(state.selectedRack && { rackNumber: { in: state.selectedRack.map((r) => r.toString()) } }),
      ...(state.searchTerm && { search: { in: [`%${state.searchTerm}%`] } }),
    },
    page: state.page,
    pagesize: state.pageSize,
  }) satisfies SFPType;

export const FilterService = createInjectable(
  // eslint-disable-next-line max-lines-per-function
  () => {
    const http = inject(HttpCommunicationService);
    const activatedRoute = inject(ActivatedRoute);
    const rumService = inject(RUMService);

    const filterInfo$ = http.get<FilterInfoGetResponseType>('/api/filter-info').pipe(
      shareReplay(1),
      catchError(() => EMPTY),
    );
    const generateFilter = getGenerateFilterFuction();

    const queryParamFilter$: Observable<any> = activatedRoute.queryParamMap.pipe(
      map((params) => {
        const status = params.get('status');
        const staining = params.getAll('staining');
        const customField1 = params.getAll('customField1');
        const selectedDateRange = params.getAll('selectedDateRange');
        const selectedFullScannerType = params.get('selectedFullScannerType');
        const selectedScannerType = params.get('selectedScannerType');
        const selectedScannerSerial = params.get('selectedScannerSerial');
        const selectedRack = params.getAll('selectedRack');
        const selectedTag = params.getAll('selectedTag');
        const searchTerm = params.get('searchTerm');
        const page = params.get('page');
        const pageSize = params.get('pageSize');

        return {
          ...(status && { status }),
          ...(staining && { staining }),
          ...(customField1 && { customField1 }),
          ...(selectedDateRange.length === 2 && {
            selectedDateRange: [parseISO(selectedDateRange[0]), parseISO(selectedDateRange[1])],
          }),
          ...(selectedFullScannerType && { selectedFullScannerType }),
          ...(selectedScannerType && { selectedScannerType }),
          ...(selectedScannerSerial && { selectedScannerSerial }),
          ...(selectedRack.length && { selectedRack: selectedRack.map((r) => Number(r)) }),
          ...(selectedTag.length && { selectedTag }),
          ...(searchTerm && { searchTerm }),
          ...(page && { page }),
          ...(pageSize && { pageSize }),
        };
      }),
    );

    const state = signalSlice({
      initialState,
      sources: [queryParamFilter$],
      actionSources: {
        updateSelectedDateRange: (_, update$: Observable<InitialState['selectedDateRange']>) =>
          update$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'date_range' })),
            map((range) => ({ selectedDateRange: [range[0], range[1]], page: 1 })),
          ),
        updateSelectedStatus: (_, status$: Observable<InitialState['status']>) =>
          status$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'status' })),
            map((status) => ({ status, selectedTag: undefined, page: 1 })),
          ),
        updateStaining: (_, staining$: Observable<InitialState['staining']>) =>
          staining$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'staining' })),
            map((staining) => ({ staining, page: 1 })),
          ),
        updateSelectedScannerType: (_, scannerType$: Observable<InitialState['selectedScannerType']>) =>
          scannerType$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'scanner_type' })),
            map((scannerType) => {
              if (!scannerType) {
                return {
                  selectedFullScannerType: undefined,
                  selectedScannerType: undefined,
                  selectedScannerSerial: undefined,
                  page: 1,
                };
              }
              const [selectedScannerType, selectedScannerSerial] = scannerType.split(/-(?=[^-]*$)/); // Only split on last occurrence of '-'
              return {
                selectedFullScannerType: scannerType,
                selectedScannerType,
                selectedScannerSerial,
                page: 1,
              };
            }),
          ),
        updateSelectedRack: (_, rack$: Observable<InitialState['selectedRack']>) =>
          rack$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'rack' })),
            map((selectedRack) => ({ selectedRack, page: 1 })),
          ),
        updateSelectedTag: (_, tag$: Observable<InitialState['selectedTag']>) =>
          tag$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'tag' })),
            map((selectedTag) => ({ selectedTag, page: 1 })),
          ),
        updatePage: (_, page$: Observable<InitialState['page']>) => page$.pipe(map((page) => ({ page }))),
        setSearchTerm: (_, searchTerm$: Observable<InitialState['searchTerm']>) =>
          searchTerm$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'search' })),
            map((searchTerm) => ({ searchTerm, page: 1 })),
          ),
        updateCustomField1: (_, customField1$: Observable<InitialState['customField1']>) =>
          customField1$.pipe(
            tap(() => rumService.awsRum()?.recordEvent('dpqa.custom.filter', { type: 'custom_field' })),
            map((customField1) => ({ customField1, page: 1 })),
          ),
      },
    });

    const filterString = computed(() => serialize(generateFilter(state())));
    const queryParamFilter = computed(() => ({
      ...state(),
      selectedDateRange: state.selectedDateRange().map((range) => formatISO(range, { representation: 'date' })),
    }));

    return {
      selectedDateRange: state.selectedDateRange,
      status: state.status,
      staining: state.staining,
      selectedScannerType: state.selectedFullScannerType,
      selectedRack: state.selectedRack,
      selectedTag: state.selectedTag,
      customField1: state.customField1,
      updateSelectedDateRange: (range: InitialState['selectedDateRange']) => state.updateSelectedDateRange(range),
      updateStatus: (status: InitialState['status']) => state.updateSelectedStatus(status),
      updateStaining: (staining: InitialState['staining']) => state.updateStaining(staining),
      updateSelectedScannerType: (scannerType: InitialState['selectedScannerType']) =>
        state.updateSelectedScannerType(scannerType),
      updateSelectedRack: (rack: InitialState['selectedRack']) => state.updateSelectedRack(rack),
      updateSelectedTag: (tag: InitialState['selectedTag']) => state.updateSelectedTag(tag),
      updatePage: (page: InitialState['page']) => state.updatePage(page),
      setSearchTerm: (searchTerm: InitialState['searchTerm']) => state.setSearchTerm(searchTerm),
      updateCustomField1: (customField1: InitialState['customField1']) => state.updateCustomField1(customField1),
      filterString,
      filterInfo$,
      searchTerm: state.searchTerm,
      page: state.page,
      pageSize: state.pageSize,
      queryParamFilter,
    };
  },
  { providedIn: 'root' },
);
